import { css } from 'aphrodite';
import * as React from 'react';
import Linkify from 'react-linkify';
import { LogoPosition } from '../../../../extViewmodels';
import { getSocialMediaPlatformLogoUrl, sortPlatformTargets } from '../../../../models/UiUtils';
import { IFileAttachment, IPostTarget, SocialMediaType } from '../../../../view/models/Api';
import videoPreviewURL from '../../../assets/video_preview.png';
import { brandSecondary } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { RadioButton } from '../../RadioButton';
import { DisclosureIcon } from '../../svgs/icons/DisclosureIcon';
import { styleSheet } from './styles';

export const MainImageWidth = 372;
export const HalfImageWidth = MainImageWidth / 2 - 1;
export const ThirdImageWidth = MainImageWidth / 3 - 2;
export const MainImageHeight = 250;
export const HalfImageHeight = 155;
export const SmallImageHeight = 210;

export enum PreviewSize {
	Md = 'md',
	Sm = 'sm',
}
interface IProps {
	previewContent?: string;
	postContent?: string;
	target?: IPostTarget;
	truncate?: boolean;
}

export const PreviewPostContentView = ({ postContent, previewContent, target, truncate }: IProps) => {
	const [showAll, setShowAll] = React.useState(false);
	const onShowAll = () => {
		setShowAll(!showAll);
	};

	const onRenderLink = (decoratedHref: string, decoratedText: string, key: number) => {
		return (
			<a
				key={key}
				className={css(baseStyleSheet.brandLink)}
				href={decoratedHref}
				target='_blank'
				rel='noreferrer'
			>{`${decoratedText}`}</a>
		);
	};

	return (
		<div className={css(styleSheet.textOnlyPadding)}>
			{target?.provider === SocialMediaType.Facebook || target?.provider === SocialMediaType.LinkedIn ? (
				<Linkify componentDecorator={onRenderLink}>{showAll ? postContent : previewContent}</Linkify>
			) : (
				<>{showAll ? postContent : previewContent}</>
			)}
			{previewContent?.length > 0 &&
				truncate &&
				(showAll ? (
					<DeprecatedCloseButton className={css(styleSheet.closeButton)} fillColor='black' onClick={onShowAll} />
				) : (
					<button onClick={onShowAll}>
						<span className={css(baseStyleSheet.fontBold)}>... Read more</span>
					</button>
				))}
		</div>
	);
};
interface IPlatformPreviewImageViewProps {
	onCtaClick?: () => void;
	onClickEnlargeImage?: (imageUrl: string) => void;
	previewSize?: PreviewSize;
	postImages?: IFileAttachment[];
	controls?: boolean;
	logoPosition?: LogoPosition;
}

export const InstagramImagePreview: React.FC<IPlatformPreviewImageViewProps> = props => {
	const { postImages, previewSize, onCtaClick, onClickEnlargeImage } = props;
	const [selectedImage, setSelectedImage] = React.useState(0);

	const onSelectedTabChanged = (indx: number) => () => {
		setSelectedImage(indx);
	};
	const count = postImages?.length;
	const isMultiple = count > 1;
	const imgHeight = previewSize === PreviewSize.Sm ? SmallImageHeight : !isMultiple ? 'auto' : MainImageHeight;

	return (
		<>
			<div
				className={css(
					previewSize !== PreviewSize.Sm ? styleSheet.imageDisplayAreaPreviewNotMultiple : undefined,
					styleSheet.imageDisplayAreaPreview,
					styleSheet.imageDisplayAreaPreviewInstagram
				)}
				style={{ position: 'relative' }}
			>
				<img
					className={css(styleSheet.tab)}
					onClick={onCtaClick ? onCtaClick : () => onClickEnlargeImage(postImages[selectedImage].url)}
					src={postImages[selectedImage].url}
					width={MainImageWidth}
					height={imgHeight}
				/>
			</div>
			<div
				className={`${css(
					styleSheet.tabNavigation,
					previewSize === PreviewSize.Sm ? styleSheet.radioBackground : undefined
				)}`}
			>
				{postImages.map((_, i) => {
					return (
						<RadioButton
							checked={i === selectedImage || false}
							className={css(styleSheet.circle)}
							id={`${postImages[selectedImage].url}-${i}`}
							key={`${postImages[selectedImage].url}-${i}`}
							onChange={onSelectedTabChanged(i)}
						/>
					);
				})}
			</div>
		</>
	);
};

export const BasicImagePreview: React.FC<IPlatformPreviewImageViewProps> = ({
	postImages,
	previewSize,
	onCtaClick,
	onClickEnlargeImage,
}) => {
	const count = postImages?.length;
	const isMultiple = count > 1;
	const imgHeight = previewSize === PreviewSize.Sm ? SmallImageHeight : !isMultiple ? 'auto' : MainImageHeight;

	return (
		<div
			className={css(
				styleSheet.imageDisplayAreaPreview,
				previewSize !== PreviewSize.Sm && isMultiple
					? styleSheet.imageDisplayAreaPreviewNotMultiple
					: styleSheet.imageDisplayAreaPreviewMultiple,
				count > 4 && styleSheet.imageDisplayAreaPreviewColWrap,
				previewSize === PreviewSize.Sm ? styleSheet.clickNav : undefined
			)}
			onClick={previewSize === PreviewSize.Sm ? onCtaClick : null}
		>
			{postImages.map((image, i) => {
				if (((count === 2 || count === 3 || count === 4) && i === 0) || !isMultiple) {
					return (
						<button onClick={onCtaClick ? null : () => onClickEnlargeImage?.(image.url)} key={`${image.url}-${i}`}>
							<img src={image.url} width={MainImageWidth} height={imgHeight} />
						</button>
					);
				}

				if (count === 3) {
					return (
						<button
							className={css(i === 2 && styleSheet.imageDisplayLeft)}
							onClick={() => onClickEnlargeImage(image.url)}
							key={`${image.url}-${i}`}
						>
							<img src={image.url} width={HalfImageWidth} height={HalfImageHeight} />
						</button>
					);
				}

				if (count === 2) {
					return (
						<button onClick={() => onClickEnlargeImage(image.url)} key={`${image.url}-${i}`}>
							<img src={image.url} width={MainImageWidth} height={MainImageHeight} />
						</button>
					);
				}

				if (count === 4) {
					return (
						<button
							className={css((i === 3 || i === 2) && styleSheet.imageDisplayLeft)}
							onClick={() => onClickEnlargeImage(image.url)}
							key={`${image.url}-${i}`}
						>
							<img
								src={image.url}
								width={i === 3 ? ThirdImageWidth : ThirdImageWidth + 1}
								height={HalfImageHeight} // These are reverse because of col wrap
							/>
						</button>
					);
				}

				return (
					<button
						className={css((i === 2 || i === 3 || i === 4) && styleSheet.imageDisplayLeft)}
						onClick={() => onClickEnlargeImage(image.url)}
						key={`${image.url}-${i}`}
					>
						<>
							<img
								src={image.url}
								width={HalfImageWidth}
								height={i === 0 || i === 1 ? HalfImageWidth - 1 : ThirdImageWidth} // These are reverse because of col wrap
							/>
						</>
					</button>
				);
			})}
		</div>
	);
};

export const LinkedInImagePreview: React.FC<IPlatformPreviewImageViewProps> = ({
	postImages,
	previewSize,
	onClickEnlargeImage,
}) => {
	const count = postImages?.length;
	const isMultiple = count > 1;
	const isOdd = count % 2;
	const imgHeight = previewSize === PreviewSize.Sm ? SmallImageHeight : !isMultiple ? 'auto' : MainImageHeight;

	return (
		<div className={css(styleSheet.imageDisplayAreaPreviewNotMultiple, styleSheet.imageDisplayAreaPreview)}>
			{postImages.map((image, i) => {
				if (((count === 3 || count === 4) && i === 0) || !isMultiple) {
					return (
						<button onClick={() => onClickEnlargeImage(image.url)} key={`${image.url}-${i}`}>
							<img src={image.url} width={MainImageWidth} height={imgHeight} />
						</button>
					);
				}

				if (count === 3 || count === 2) {
					return (
						<button
							className={css(isOdd && i === 2 && styleSheet.imageDisplayLeft)}
							onClick={() => onClickEnlargeImage(image.url)}
							key={`${image.url}-${i}`}
						>
							<img src={image.url} width={HalfImageWidth} height={count === 2 ? 476 : HalfImageHeight} />
						</button>
					);
				}

				if (count === 4) {
					return (
						<button
							className={css((i === 2 || i === 3) && styleSheet.imageDisplayLeft)}
							onClick={() => onClickEnlargeImage(image.url)}
							key={`${image.url}-${i}`}
						>
							<img src={image.url} width={i === 3 ? ThirdImageWidth : ThirdImageWidth + 1} height={HalfImageHeight} />
						</button>
					);
				}

				return (
					<button
						className={css((i === 1 || i === 3 || i === 4) && styleSheet.imageDisplayLeft)}
						onClick={() => onClickEnlargeImage(image.url)}
						key={`${image.url}-${i}`}
					>
						<img
							src={image.url}
							width={i === 0 || i === 1 ? HalfImageWidth : i === 4 ? ThirdImageWidth : ThirdImageWidth + 1}
							height={i === 0 || i === 1 ? 240 : HalfImageHeight}
						/>
					</button>
				);
			})}
		</div>
	);
};
export const BasicVideoPreview: React.FC<IPlatformPreviewImageViewProps> = ({
	postImages,
	previewSize,
	onCtaClick,
	controls,
}) => {
	return (
		<div
			className={css(
				styleSheet.imageDisplayAreaPreview,
				previewSize !== PreviewSize.Sm
					? styleSheet.imageDisplayAreaPreviewNotMultiple
					: styleSheet.imageDisplayAreaPreviewMultiple,
				previewSize === PreviewSize.Sm ? styleSheet.clickNav : undefined
			)}
			onClick={previewSize === PreviewSize.Sm ? onCtaClick : null}
		>
			{postImages.map((image, i) => {
				return (
					<div className={css(styleSheet.videoWrapper)} key={`${image.url}-${i}`}>
						{controls ? (
							<video
								key={`${image.url}-${i}`}
								src={image.url}
								width={MainImageWidth}
								height={MainImageHeight} // These are reverse because of col wrap
								controls={controls}
							/>
						) : (
							<img src={videoPreviewURL} width='100%' />
						)}
					</div>
				);
			})}
		</div>
	);
};

export const PreviewImages = ({
	postTargets,
	targetIndex,
	postImages,
	displayContent,
	postContent,
	contentDisplayLength,
	onClickEnlargeImage,
}: {
	postTargets: IPostTarget[];
	targetIndex: number;
	postImages: IFileAttachment[];
	displayContent: string;
	postContent: string;
	contentDisplayLength: number;
	onClickEnlargeImage: (imageUrl: string) => void;
}) => {
	return (
		<figure
			className={css(
				styleSheet.previewContentBox,
				postTargets?.[targetIndex]?.provider === SocialMediaType.Instagram &&
					postImages?.length > 1 &&
					styleSheet.previewContentBoxInstagram
			)}
		>
			<PreviewPostContentView
				previewContent={displayContent}
				postContent={postContent}
				truncate={postContent?.length > contentDisplayLength}
				target={postTargets?.[targetIndex]}
			/>
			{postImages?.length > 0 ? (
				postImages.some(x => x.mimeType?.startsWith('video/')) ? (
					<BasicVideoPreview postImages={postImages} controls={true} />
				) : postTargets?.[targetIndex]?.provider === SocialMediaType.Instagram && postImages?.length > 1 ? (
					<InstagramImagePreview postImages={postImages} onClickEnlargeImage={onClickEnlargeImage} />
				) : postTargets?.[targetIndex]?.provider === SocialMediaType.LinkedIn ? (
					<LinkedInImagePreview postImages={postImages} onClickEnlargeImage={onClickEnlargeImage} />
				) : (
					<BasicImagePreview postImages={postImages} onClickEnlargeImage={onClickEnlargeImage} />
				)
			) : null}
		</figure>
	);
};

export const PreviewPostTargets = ({
	postTargets,
	onSetTargetPreview,
	targetIndex,
}: {
	postTargets: IPostTarget[];
	onSetTargetPreview: (index?: number) => () => void;
	targetIndex: number;
}) => {
	return (
		<p className={css(styleSheet.iconRow)}>
			{postTargets?.length > 1 && (
				<button onClick={onSetTargetPreview(Math.max(targetIndex - 1, 0))}>
					<DisclosureIcon fillColor={brandSecondary} type='chevron' className={css(styleSheet.pointLeft)} />
				</button>
			)}
			{sortPlatformTargets(postTargets)?.map((x, i) => (
				<span className={css(styleSheet.iconRow)} key={`${x}-${i}`}>
					<button
						className={
							x === postTargets?.[targetIndex]
								? css(styleSheet.iconRow, styleSheet.iconRowSelected)
								: css(styleSheet.iconRow)
						}
						onClick={onSetTargetPreview(i)}
					>
						<img key={i} src={getSocialMediaPlatformLogoUrl(x.provider)} />
					</button>
				</span>
			))}
			{postTargets?.length > 1 && (
				<button onClick={onSetTargetPreview(Math.min(targetIndex + 1, postTargets.length - 1))}>
					<DisclosureIcon fillColor={brandSecondary} type='chevron' className={css(styleSheet.pointRight)} />
				</button>
			)}
		</p>
	);
};
