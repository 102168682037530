import { StyleSheet } from 'aphrodite';
import { background, brandPrimaryText, header, mention } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	circle: {
		padding: 0,
	},
	clickNav: {
		':hover': {
			cursor: 'pointer',
		},
	},
	closeButton: {
		marginLeft: 5,
	},
	disclaimerText: {
		fontSize: 12,
		paddingBottom: 25,
		textAlign: 'center',
		width: '70%',
	},
	iconRow: {
		alignItems: 'center',
		display: 'flex',
		height: 38,
		justifyContent: 'center',
		margin: '0 auto',
		padding: 4,
		...CssSelectors.allChildren(
			{
				height: 24,
				opacity: 0.5,
			},
			'img'
		),
	},
	iconRowSelected: {
		...CssSelectors.allChildren(
			{
				height: 38,
				opacity: 1,
			},
			'img'
		),
	},
	imageDisplayAreaPreview: {
		background,
		boxSizing: 'border-box',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		overflow: 'hidden',
		padding: 0,
		position: 'relative',
		...CssSelectors.allDescendants(
			{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				paddingBottom: 2,
			},
			'button'
		),
		...CssSelectors.allDescendants(
			{
				objectFit: 'cover',
			},
			'img'
		),
	},
	imageDisplayAreaPreviewColWrap: {
		flexDirection: 'column',
		height: 372,
	},
	imageDisplayAreaPreviewInstagram: {
		justifyContent: 'center',
		padding: 0,
		...CssSelectors.allDescendants(
			{
				maxHeight: 'auto',
				padding: 0,
			},
			'img'
		),
	},
	imageDisplayAreaPreviewMultiple: {
		paddingBottom: 24,
	},
	imageDisplayAreaPreviewNotMultiple: {
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
	},
	imageDisplayLeft: {
		paddingLeft: 2,
		position: 'relative',
	},

	pointLeft: {
		height: 15,
		transform: 'rotateZ(90deg)',
		width: 20,
	},
	pointRight: {
		height: 15,
		transform: 'rotateZ(270deg)',
		width: 20,
	},
	previewContentBox: {
		background: 'white',
		borderRadius: 8,
		color: 'black',
		fontSize: 14,
		marginBottom: 20,
		marginTop: 20,
		opacity: 1,
		overflowWrap: 'break-word',
		transform: 'translate3d(0,0,0)',
		transition: 'opacity 0.2s, transform 0.5s',
		whiteSpace: 'pre-wrap',
		width: 372,
		wordBreak: 'break-word',
		wordWrap: 'break-word',
	},
	previewContentBoxInstagram: {},
	previewContentContainer: {
		color: brandPrimaryText,
		fontSize: 16,
		overflow: 'auto',
	},
	previewContentSection: {
		alignItems: 'center',
		background: mention,
		display: 'flex',
		flexDirection: 'column',
		paddingTop: 30,
	},
	previewHeader: {
		margin: '0 auto',
	},
	radioBackground: {
		background,
	},
	tab: {
		alignItems: 'center',
		boxSizing: 'border-box',
		color: header,
		cursor: 'pointer',
		display: 'flex',
		flexShrink: 0,
		fontSize: 12,
		justifyContent: 'center',
		letterSpacing: 1.05,
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
	},
	tabNavigation: {
		...CssSelectors.allChildren(
			{
				paddingLeft: 6,
			},
			':not(:first-child), label'
		),
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		paddingBottom: 6,
		paddingTop: 6,
	},
	textOnlyPadding: {
		padding: 14,
	},
	videoWrapper: {
		position: 'relative',
	},
});
