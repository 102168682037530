import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import React from 'react';
import Measure, { ContentRect } from 'react-measure';
import { brandSecondary } from '../../../styles/colors';
import { RadioButton } from '../../RadioButton';
import { DisclosureIcon } from '../../svgs/icons/DisclosureIcon';
import { LightBulbIcon } from '../../svgs/icons/LightBulbIcon';
import { styleSheet } from './styles';

export const SocialMediaImagesPreviewAlert = () => {
	return (
		<div className={css(styleSheet.infoWrapper)}>
			<figure>
				<LightBulbIcon fill={brandSecondary} />
			</figure>
			<p>
				This is a preview of the logo overlay. The logo size is an approximation and may vary. The final image will be
				posted to the selected targets.
			</p>
		</div>
	);
};

export const SocialMediaImagesPreviewImages = ({
	images,
	currentImageIndex,
	showOverlayLogo,
	url,
	onImageIndexChange,
}: {
	images: Api.IFileAttachment[];
	currentImageIndex: number;
	showOverlayLogo: boolean;
	url: string;
	onImageIndexChange: (index: number) => () => void;
}) => {
	const [logoSize, setLogoSize] = React.useState({ width: 0, height: 0 });
	const onContentResize = React.useCallback(
		(contentRect: ContentRect) => {
			setLogoSize({ ...logoSize, width: contentRect.client.width, height: contentRect.client.height });
		},
		[logoSize]
	);

	return (
		<Measure client={true} onResize={onContentResize}>
			{({ measureRef }) => {
				return (
					<div className={css(styleSheet.container, images?.length === 1 ? styleSheet.containerCenter : undefined)}>
						{images?.length > 1 ? (
							<button onClick={onImageIndexChange(currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1)}>
								<DisclosureIcon fillColor={brandSecondary} type='chevron' className={css(styleSheet.pointLeft)} />
							</button>
						) : null}
						<figure className={css(styleSheet.imageContainer)}>
							<picture>
								<img
									src={images?.[currentImageIndex]?.url}
									alt={images?.[currentImageIndex]?.fileName}
									className={css(styleSheet.mainImage)}
								/>
							</picture>

							{showOverlayLogo ? (
								<picture
									className={css(
										styleSheet.logoWrapper,
										logoSize.height > logoSize.width ? styleSheet.logoWrapperCapHeight : null
									)}
								>
									<img src={url} ref={measureRef} />
								</picture>
							) : null}
						</figure>
						{images?.length > 1 ? (
							<button onClick={onImageIndexChange(currentImageIndex === images.length - 1 ? 0 : currentImageIndex + 1)}>
								<DisclosureIcon fillColor={brandSecondary} type='chevron' className={css(styleSheet.pointRight)} />
							</button>
						) : null}
					</div>
				);
			}}
		</Measure>
	);
};

export const SocialMediaImagesPreviewRadioButtons = ({
	currentImageIndex,
	images,
	onImageIndexChange,
}: { currentImageIndex: number; images: Api.IFileAttachment[]; onImageIndexChange: (index: number) => () => void }) => {
	return (
		<div className={`${css(styleSheet.tabNavigation)}`}>
			{images.map((_, i) => {
				return (
					<RadioButton
						checked={i === currentImageIndex || false}
						className={css(styleSheet.circle)}
						id={`images-${i}`}
						key={`images-${i}`}
						onChange={onImageIndexChange(i)}
					/>
				);
			})}
		</div>
	);
};
